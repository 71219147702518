import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessagesPopups } from "@app/helpers/messagesPopups";

import { LoginService } from '@app/services/login.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private messagesPopups: MessagesPopups,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if ([401, 403].includes(err.status) && this.loginService.userValue) {
        // auto logout if 401 or 403 response returned from api
        this.loginService.logout();
        this.messagesPopups.popupMessage('Token Expirado');
      }

      const error = err.error?.message || err.statusText;
      return throwError(() => error);
    }))
  }
}