import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ResponseModel } from '@app/models/responseModel';

@Injectable({ providedIn: 'root' })
export class PrivilegeService {

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  grantRole(idRole:string, idPage:string) {
    return this.http.post<ResponseModel>(`${environment.apiUrl}/privilege/grant`, {id_role: idRole, id_page: idPage})
    .pipe(map(response => {
      return response;
    }));
  }

  denyRole(idRole:string, idPage:string) {
    return this.http.delete<ResponseModel>(`${environment.apiUrl}/privilege/deny`,  { body: {id_role: idRole, id_page: idPage}})
    .pipe(map(response => {
      return response;
    }));
  }

  getGrantByIdRole() {
    return this.http.get<ResponseModel>(`${environment.apiUrl}/privilege/getGrantByIdRole`)
    .pipe(map(response => {
      return response;
    }));
  }

  getPrivilegeByIdRole(idRol: string) {
    return this.http.get<any>(`${environment.apiUrl}/privilege/getPrivilegeByIdRole/${idRol}`)
    .pipe(map(response => {
      return response;
    }));
  }
}
