import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class MessagesPopups {
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  
  constructor(
    private _snackBar: MatSnackBar
  ) {

  }
  
  popupMessage(message: string) {
    this._snackBar.open(message, 'OK', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000,
    });
    
  }

  swalError(message: string){
    Swal.fire({
      title: 'Error!', 
      text: message,
      icon: 'error',
      confirmButtonText: 'Cerrar!'
    })
  }

  swalSuccess(message: string){
    Swal.fire({
      title: 'Genial!', 
      text: message,
      icon: 'success',
      confirmButtonText: 'Listo!'
    })
  }

  swalWarning(message: string){
    Swal.fire({
      title: 'Ups!', 
      text: message,
      icon: 'warning',
      confirmButtonText: 'Listo!'
    })
  }
}