import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { UserModel } from '@app/models/loginModel';

@Injectable({ providedIn: 'root' })
export class LoginService {
  private userSubject: BehaviorSubject<UserModel | null>;
  public user: Observable<UserModel | null>;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('userAppBase')!));
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  login(username: string, pass: string) {
    const data = {
      "username": username,
      "pass": pass
    }
    return this.http.post<UserModel>(`${environment.apiUrl}/auth`, data)
    .pipe(map(user => {
      localStorage.setItem('userAppBase', JSON.stringify(user));
      this.userSubject.next(user);
      return user;
    }));
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('userAppBase');
    this.userSubject.next(null);
    this.router.navigate(['/authentication/side-login']);
  }
}
